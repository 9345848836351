<template>
  <div class="mainwhite">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        label="结算设置"
        name="settleSet"
        v-if="myfun.checkAuthRule('adminSystemSetSettleConfig')"

      >
        <el-form v-loading="settleSetFormDataLoading"
          class="huiyuanxinxi"
          :model="settleSetFormData"
          :rules="settleSetCheckFormDataRules"
          ref="settleSetFormData"
        >
          <el-form-item class="suoding"
                                    label="推荐奖结算比例"
                                    :label-width="formLabelWidth"
                                    prop="award_agent_level8_repeat_pv"
                                    required
                                    style="text-align: left"
                      >
                        <el-input
                            type="number"
                            v-model="settleSetFormData.referral_award_settlement_ratio"
                            autocomplete="off"
                            style="width: 300px"
                        >
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
<!--          <el-divider content-position="left">基础配置</el-divider>-->
<!--          <el-form-item-->
<!--            class="suoding"-->
<!--            label="结算锁定"-->
<!--            :label-width="formLabelWidth"-->
<!--            prop="lock"-->
<!--            style="text-align: left"-->
<!--          >-->
<!--            <el-switch-->
<!--              class="switch custom"-->
<!--              v-model="settleSetFormData.lock"-->
<!--              :active-value="'1'"-->
<!--              :inactive-value="'0'"-->
<!--              active-text="开启"-->
<!--              inactive-text="关闭"-->
<!--              active-color="#f56c6c"-->
<!--            ></el-switch>-->
<!--          </el-form-item>-->
<!--          <el-divider content-position="left">奖衔统计配置</el-divider>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="大区翻倍"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop=""-->

<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-radio v-model="settleSetFormData.award_big_region_doubled" name="award_big_region_doubled" label="1">是</el-radio>-->
<!--            <el-radio v-model="settleSetFormData.award_big_region_doubled" label="0">否</el-radio>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="小区翻倍"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop=""-->

<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-radio v-model="settleSetFormData.award_small_region_doubled" name="award_small_region_doubled" label="1">是</el-radio>-->
<!--            <el-radio v-model="settleSetFormData.award_small_region_doubled" label="0">否</el-radio>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="大小区互转"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop=""-->

<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-radio v-model="settleSetFormData.award_big_small_region_conversion" name="award_big_small_region_conversion" label="1">是</el-radio>-->
<!--            <el-radio v-model="settleSetFormData.award_big_small_region_conversion" label="0">否</el-radio>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--              label="皇冠特使重消"-->
<!--              :label-width="formLabelWidth"-->
<!--              prop="award_agent_level9_repeat_pv"-->
<!--              required-->
<!--              style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level9_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="皇冠大使重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level8_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level8_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="皇冠重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level8_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level7_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="黑钻重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level6_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level6_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="蓝钻重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level5_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level5_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="钻石重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level4_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level4_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="翡翠重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level3_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level3_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="明珠重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level2_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level2_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item class="suoding"-->
<!--                        label="宝石重消"-->
<!--                        :label-width="formLabelWidth"-->
<!--                        prop="award_agent_level1_repeat_pv"-->
<!--                        required-->
<!--                        style="text-align: left"-->
<!--          >-->
<!--            <el-input-->
<!--                type="number"-->
<!--                v-model="settleSetFormData.award_agent_level1_repeat_pv"-->
<!--                autocomplete="off"-->
<!--                style="width: 300px"-->
<!--            >-->
<!--              <template slot="append">PV</template>-->
<!--            </el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" @click="settleConfigSet()"
              >保存配置</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
<!--      <el-tab-pane label="奖衔统计设置" name="third">奖衔统计设置</el-tab-pane>-->
      <!--      <el-tab-pane label="定时任务补偿" name="fourth">定时任务补偿</el-tab-pane>-->
      <el-tab-pane
        label="修改密码"
        name="modifyPassword"
        v-if="myfun.checkAuthRule('adminUserModifyPassword')"
        v-loading="loading"
      >
        <el-form
          class="huiyuanxinxi mimay"
          ref="passwordFormData"
          :model="passwordFormData"
          :rules="checkPasswordFormDataRules"
        >
          <el-form-item
            label="原密码"
            prop="old_password"
            :label-width="formLabelSmallWidth"
            required
          >
            <el-input
              type="password"
              v-model="passwordFormData.old_password"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="新密码"
            prop="password"
            :label-width="formLabelSmallWidth"
            required
          >
            <el-input
              type="password"
              v-model="passwordFormData.password"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="确认密码"
            prop="confirm_password"
            :label-width="formLabelSmallWidth"
            required
          >
            <el-input
              type="password"
              v-model="passwordFormData.confirm_password"
              style="width: 300px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              plain
              @click="resetFormData('passwordFormData')"
            >
              重置
            </el-button>
            <el-button type="primary" @click="passwordSubmit()">
              确认修改
            </el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "BasicSettings",
  data() {
    var checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordFormData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      activeName: "settleSet",
      test: true,
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        searchField: "1",
      },
      editNetformData: {
        id: "0",
        uname: "",
        full_name: "",
        recommend_uname: "",
        parent_uname: "",
        net_postition: "左",
        type: "",
        member_level: "",
        remark: "",
      },
      editMemberformData: {
        id: "0",
        rate: "",
        cash_mark: "",
      },
      checkEditNetformRules: {
        recommend_uname: [{ required: true, message: "请输入推荐人编号" }],
        parent_uname: [{ required: true, message: "请输入安置人编号" }],
      },
      checkEditMemberformRules: {
        full_name: [{ required: true, message: "请输入会员姓名" }],
        mobile: [{ required: true, message: "请输入手机号" }],
      },
      settleSetFormData: {
        lock: "1",
      },
      settleSetCheckFormDataRules: {
        award_agent_level1_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level2_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level3_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level4_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level5_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level6_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level7_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level8_repeat_pv: [{ required: true, message: "请输入重消PV" }],
        award_agent_level9_repeat_pv: [{ required: true, message: "请输入重消PV" }],
      },
      tableData: [],
      loading: false,
      settleSetFormDataLoading: false,
      operateTitle: "修改推荐人",
      formLabelWidth: "300px",
      formLabelSmallWidth: "100px",
      editNetdialogFormVisible: false,
      editMemberdialogFormVisible: false,
      memberSettingFormData: {
        distributor_level1_valid_period: 0,
        distributor_level2_valid_period: 0,
        distributor_level3_valid_period: 0,
        distributor_level4_valid_period: 0,
        distributor_level5_valid_period: 0,
        distributor_level6_valid_period: 0,
      },
      memberSettingCheckFormDataRules: {
        distributor_level1_valid_period: [
          { required: true, message: "请设置待审会员有效期" },
        ],
        distributor_level2_valid_period: [
          { required: true, message: "请设置优惠顾客有效期" },
        ],
        distributor_level3_valid_period: [
          { required: true, message: "请设置超级优惠顾客有效期" },
        ],
        distributor_level4_valid_period: [
          { required: true, message: "请设置白金卡有效期" },
        ],
        distributor_level5_valid_period: [
          { required: true, message: "请设置钻石卡有效期" },
        ],
        distributor_level6_valid_period: [
          { required: true, message: "请设置至尊卡有效期" },
        ],
      },
      passwordFormData: {
        old_password: "",
        password: "",
        confirm_password: "",
      },
      checkPasswordFormDataRules: {
        old_password: [{ required: true, message: "请输入原密码" }],
        password: [{ required: true, message: "请输入新密码" }],
        confirm_password: [{ validator: checkPassword, trigger: "blur" }],
      },
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      // this.loading = true;
      // this.myfun
      //   .request("adminSystemCountryPaginate.api", this.formData)
      //   .then((result) => {
      //     if (result.code === "1") {
      //       this.tableData = result.data.list;
      //     }
      //     this.formData.total = parseInt(result.data.total);
      //     this.loading = false;
      //   });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    editNet(row, type) {
      this.operateTitle = type === 1 ? "修改推荐人" : "修改安置人";
      this.editNetformData.type = type;
      this.editNetformData.member_level = row.member_level;
      this.editNetformData.id = row.id;
      this.editNetformData.uname = row.uname;
      this.editNetformData.full_name = row.full_name;

      if (type === 1) {
        this.editNetformData.parent_uname = "111111";
        this.editNetformData.net_postition = "左";
      } else if (type === 2) {
        this.editNetformData.recommend_uname = "111111";
      }

      if (row.recommend_info !== "") {
        this.editNetformData.recommend_uname = row.recommend_info.uname;
      }

      if (row.parent_info !== "") {
        this.editNetformData.parent_uname = row.parent_info.uname;
        this.editNetformData.net_postition = row.net_postition_str;
      }

      this.editNetdialogFormVisible = true;
    },
    editMember(row) {
      console.log(row);
      this.editMemberformData.id = row.id;
      this.editMemberformData.rate = row.rate;
      this.editMemberformData.cash_mark = row.cash_mark;
      this.editMemberdialogFormVisible = true;
    },
    doEditMember(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun
            .request(
              "adminSystemCountryEditDo.api",
              this.editMemberformData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.getList();
                this.editMemberdialogFormVisible = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSearch() {
      this.getList(1);
    },
    changeCountryStatus(value, id) {
      //验证通过执行请求
      this.loading = true;
      this.myfun
        .request(
          "adminSystemCountryEditDo.api",
          {
            id: id,
            status: value,
          },
          "post"
        )
        .then((result) => {
          this.loading = false;
        });
    },
    memberConfigSet() {
      this.$refs["memberSettingFormData"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun
            .request(
              "adminSystemSetMemberConfig.api",
              this.memberSettingFormData,
              "post"
            )
            .then((result) => {
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    settleConfigSet() {
      this.$refs["settleSetFormData"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.settleSetFormDataLoading = true;
          this.myfun
            .request(
              "adminSystemSetSettleConfig.api",
              this.settleSetFormData,
              "post"
            )
            .then((result) => {
              this.settleSetFormDataLoading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }

      });
    },
    passwordSubmit() {
      this.$refs["passwordFormData"].validate((valid) => {
        if (valid) {
          //验证通过执行请求
          this.loading = true;
          this.myfun
            .request(
              "adminUserModifyPassword.api",
              this.passwordFormData,
              "post"
            )
            .then((result) => {
              if (result.code === "1") {
                this.resetFormData("passwordFormData");
              }
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetFormData(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.getList(1);
    this.settleSetFormDataLoading = true;
    this.myfun.request("adminSystemBasicSettingDeputy.api").then((result) => {
      if (result.code === "1") {
        this.memberSettingFormData = result.data.memberConfig;
        this.settleSetFormData = result.data.settleConfig;
        this.settleSetFormDataLoading = false;
      }
    });
  },
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
.el-form-item {
  margin-bottom: 0;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
/* switch按钮样式 */
.el-switch {
  margin: 8px;
}
.switch.custom .el-switch__label--left span {
  margin-right: 5px;
}
.switch.custom .el-switch__label--right span {
  margin-left: 5px !important;
  margin-right: 20px !important;
}
.switch.custom .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch.custom .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch.custom .el-switch__label--right span {
  margin-left: 5px;
}
/*关闭时文字位置设置*/
.switch.custom .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch.custom .el-switch__label--left span {
  margin-left: 20px;
}
/*显示文字*/
.switch.custom .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch.custom.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 82px !important;
  margin: 0;
}
.batch_manage_tags {
  margin-right: 10px;
}
.batch_manage_switch {
  margin-right: 10px;
}
/* 结算锁定 */
.suoding {
  padding: 5px 0px 25px;
}
.suoding .el-form-item__label {
  width: 120px !important;
  text-align: left;
}
.suoding .el-form-item__content {
  margin-left: 120px !important;
}
.shezhiy .el-form-item__label {
  text-align: left;
}
.mimay {
  padding-top: 10px;
}
.mimay .el-form-item__label {
  text-align: left;
}
.mimay .el-button {
  margin-top: 30px;
}
</style>
