<template>
  <div>
    <el-form ref="formData" :model="formData" :rules="checkFormDataRules" label-width="80px" v-loading="loading" style="width: 500px; margin: 200px auto">
      <el-form-item label="原密码" prop="old_password" required>
        <el-input type="password"  v-model="formData.old_password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password" required>
        <el-input type="password"  v-model="formData.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirm_password" required>
        <el-input type="password"  v-model="formData.confirm_password"></el-input>
      </el-form-item>
      <el-form-item style="text-align: right">
        <el-button type="primary" plain @click="resetFormData('formData')" style="width: 30%">重置</el-button>
        <el-button type="primary" @click="onSubmit('formData')" style="width: 30%">确认修改</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: 'ModifyPassword',
    data() {
      var checkPassword = (rule, value, callback) => {

        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.formData.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        loading: false,
        formData: {
          old_password: '',
          password: '',
          confirm_password: ''
        },
        checkFormDataRules: {
          old_password: [
            { required: true, message: "请输入原密码"},
          ],
          password: [
            { required: true, message: "请输入新密码"},
          ],
          confirm_password: [
            { validator: checkPassword, trigger: 'blur'},
          ]
        },
      }
    },
    methods: {
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            //验证通过执行请求
            this.loading = true;
            this.myfun.request('adminUserModifyPassword.api', this.formData)
              .then((result) => {
                if (result.code === "1") {
                  this.resetFormData(formName);
                }
                this.loading = false;
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      resetFormData(formName){
        this.$refs[formName].resetFields();
      }
    },
    created() {
    }

  }
</script>

<style scoped>

</style>
