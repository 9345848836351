<template>
  <div class="mainwhite">
    <div class="mainwhite-tiop">
      <el-form
        :inline="true"
        ref="formData"
        :model="formData"
        class="demo-form-inline"
        style="text-align: left"
      >
        <el-form-item>
          <el-select
            style="width: 135px"
            class="el-select-smid"
            v-model="formData.search_status"
            placeholder="任务状态"
            clearable
          >
            <el-option label="全部" value="-1"></el-option>
            <el-option label="已完成" value="200"></el-option>
            <el-option label="待处理" value="0"></el-option>
            <el-option label="处理中" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch('formData')"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      stripe
      border
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column
        align="center"
        header-align="center"
        prop="id"
        label="#ID"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="title"
        label="任务名称"
        width="300"
      >
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          prop="type_str"
          label="类型"
          width="100"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="user_info"
        label="操作人"
        width="150"
      >
        <template slot-scope="scope">
          <span
            v-html="
              '用户名：' +
              scope.row.uname +
              '<br />' +
              '姓名：' +
              scope.row.full_name
            "
          ></span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="total"
        label="预计总数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="explor_file_num"
        label="导出文件数"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="status_str"
        label="状态"
        width="100"
      >
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          prop="complete_ratio"
          label="进度" width="150"
      >

        <template slot-scope="scope">
          <el-progress type="circle" :percentage="parseFloat(scope.row.complete_ratio)"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        prop="created_at"
        label="创建时间" width="100"
      >
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          prop="updated_at"
          label="最后更新" width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        fixed="right"
        label="导入文件列表"
      >
        <template slot-scope="scope">
<!--          file_list_json-->

          <el-link type="primary" v-for="(item, index) in scope.row.import_file_list_json" :key="index" :href="item.path" style="display: block">{{item.file_name}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          header-align="center"
          fixed="right"
          label="导出文件列表"
      >
        <template slot-scope="scope">
          <!--          file_list_json-->

          <el-link type="primary" v-for="(item, index) in scope.row.explor_file_list_json" :key="index" :href="item.path" style="display: block">{{item.file_name}}</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination
        background
        style="margin-top: 1rem"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="formData.page"
        :page-sizes="pageSizs"
        :page-size="formData.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="formData.total"
      >
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "ExplorTaskList",
  components: {},
  data() {
    return {
      test: true,
      countryList: [],
      order_type_list: [],
      formData: {
        page: 1,
        pagesize: 10,
        total: 0,
        keywords: "",
        search_status: "-1",
      },
      tableData: [],
      loading: false,
      formLabelWidth: "120px",
    };
  },
  methods: {
    getList(page = 0) {
      this.tableData = [];
      if (page > 0) {
        this.formData.page = page;
      }
      this.loading = true;
      this.myfun.request("adminSystemGetExplorTaskList.api", this.formData)
        .then((result) => {
          if (result.code === "1") {
            this.tableData = result.data.list;
          }
          this.formData.total = parseInt(result.data.total);
          this.loading = false;
        });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.formData.pagesize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.formData.page = val;
      this.getList();
    },
    onSearch() {
      this.getList(1);
    },
  },
  created() {
    this.getList();
  },
  mounted() {},
  computed: {
    pageSizs() {
      return this.$store.state.pageSizes;
    },
  },
};
</script>

<style>
.mainwhite {
  background-color: #fff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}
.mainwhite-tiop {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
  justify-content: flex-end;
}

.el-form-item {
  margin-bottom: 0;
}
.huiyuanxinxi .el-form-item {
  margin: 0 0 15px 0;
}
</style>
